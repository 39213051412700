@import "../../themes/ionic.globals";

// Modals
// --------------------------------------------------

/// @prop - Min width of the modal inset
$modal-inset-min-width:         768px;

/// @prop - Minimum height of the small modal inset
$modal-inset-min-height-small:  600px;

/// @prop - Minimum height of the large modal inset
$modal-inset-min-height-large:  768px;

/// @prop - Width of the large modal inset
$modal-inset-width:             600px;

/// @prop - Height of the small modal inset
$modal-inset-height-small:      500px;

/// @prop - Height of the large modal inset
$modal-inset-height-large:      600px;

/// @prop - Text color of the modal content
$modal-text-color:              $text-color;

/// @prop - Padding top of the sheet modal
$modal-sheet-padding-top:        6px;

/// @prop - Padding bottom of the sheet modal
$modal-sheet-padding-bottom:     6px;
